import { template as template_17abe46e13a54df0b0f5829de69d5d38 } from "@ember/template-compiler";
const FKText = template_17abe46e13a54df0b0f5829de69d5d38(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
