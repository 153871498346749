import { template as template_e4cb9e6ac74a4809895458766d629152 } from "@ember/template-compiler";
const FKLabel = template_e4cb9e6ac74a4809895458766d629152(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
